import { useState } from 'react';
import axios from 'axios';
import './Register.css'
export const Register = () =>
{
   const[username,setUserName] = useState("");
   const[password,setPassword] = useState("");

   const register = async () =>
   {
      console.log(username);
      console.log(password);
       try{
          const response =await axios.post("https://receipe-api.debeshp.com/users/register", {username,password});
          alert(response.data.message);
       }
       catch(err)
       {
         console.log(err);
       }
   }

   return (<div className='register'>
    <div className='wrapper'>
    <form>
    <h1>Registration</h1>
    <label htmlFor="username">Username:</label>
    <input type="text" id="username" onChange={ (event) => setUserName(event.target.value)  } /><br />
    <label htmlFor="password">Password:</label>
    <input type="password" id="password" onChange={ (event) => setPassword(event.target.value)  } /><br />
    <button type='button' onClick={register} className='regBtn'>Sign Up</button>
    </form>
    </div>
   </div>) ;
};