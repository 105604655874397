import{useState} from 'react';
import axios from 'axios';
import "./CreateRecipe.css";
import {useCookies } from 'react-cookie';
export const CreateRecipe = () =>
{
   const[name,setName] = useState("");
   const[instructions,setInstructions] = useState("");
   const[image_url,setImageUrl] = useState("");
   const[cooking_duration,setCookingDuration] = useState("");
   const[user_owner,setUserOwner] = useState("");
   const[cookie,setCookies] = useCookies(["access_token"]);

   const createReceipe = async () =>
   {
     try
     {
      const id = window.localStorage.getItem("user_id");
      const response = await axios.post("https://receipe-api.debeshp.com/receipe",{name,instructions,image_url,cooking_duration,user_owner:id},
      {headers :{authorization : cookie.access_token}});
      alert(response.data.message);
     }
     catch(err)
     {
      console.log(err);
     }
   };

   return (<div className='createReceipe'>
   <div className='createReceipewrapper'>
   <form>
   <h1>Create Your Receipe</h1>
   <label htmlFor="name">Name:</label>
   <input type="text" id="name" onChange={ (event) => setName(event.target.value)  } /><br />
   <label htmlFor="instructions">Instructions:</label>
   <input type="text" id="instructions" onChange={ (event) => setInstructions(event.target.value)  } /><br />
   <label htmlFor="image_url">Image_Url:</label>
   <input type="text" id="image_url" onChange={ (event) => setImageUrl(event.target.value)  } /><br />
   <label htmlFor="cooking_duration">Cooking_Duration:</label>
   <input type="number" id="cooking_duration" onChange={ (event) => setCookingDuration(event.target.value)  } /><br />
   <button type='button' onClick={createReceipe} className='createBtn'>Submit</button>
   
   </form>
   </div>
  </div>) ;
};