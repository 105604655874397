import {useEffect,useState} from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
export const SavedRecipe = () =>
{
   const[list,setList] = useState([]);
   const u_id = window.localStorage.getItem("user_id");
   const[cookie,setCookies] = useCookies(["access_token"]);
   const[dummy,setDummy] = useCookies(0);
   const getList = async () =>
   {
      try{
       const resp = await axios.get(`https://receipe-api.debeshp.com/receipe/save/${u_id}`,{headers : {authorization : cookie.access_token}});
       console.log(resp.data);
       setList(resp.data);
      }
      catch(err)
      {
         console.log(err);
      }
       

   };

   const removeRecipes = async (receipe_id) =>
   {
       try{
           await axios.delete("https://receipe-api.debeshp.com/receipe/saved", { data : {user_id : u_id , receipe_id : receipe_id }});
           alert("Successfully Removed...!!!");
           setDummy(dummy+1);
       }
       catch(err)
       {
         console.log(err);
       }
   };

   useEffect(()=>
   {
      getList();
   },[dummy]);
   
   return (<div className="savedReceipes">
      
         {list?.length > 0 ? (
            <div className="container">
              {list.map((receipe) => 
              (<div className="receipeDisplay">
                <h2>{receipe.name}</h2>
                <img src={receipe.image_url} />
                <button type='button' className='disBtn' onClick={ () => removeRecipes(receipe._id) }>Remove</button>
                 <h3><b>Cooking-Duration:{receipe.cooking_duration}-mins</b></h3>
                 <p><b>Instructions: {receipe.instructions}</b></p>
               </div>))}
            </div>
          ) : (
            <div className="empty">
              <h2>No saved items...!!!</h2>
            </div>
          )}
      
   
   </div>) ;
};