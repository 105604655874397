import axios from 'axios';
export const Receipe = (props) =>
{

    const{_id,name,instructions,image_url,cooking_duration} = props.receipe;

    const saveReceipes = async () =>
    {
       try{
          const user_id= window.localStorage.getItem("user_id");
          const receipe_id = _id;
          const res = await axios.put("https://receipe-api.debeshp.com/receipe/save", {user_id,receipe_id});
          alert("saved...");
       }
       catch(err)
       {
        alert("already saved...");
       }
    };
    return (
        <div className="receipeDisplay">
        <h2>{name}</h2>
        <img src={image_url} />
         <h3><b>Cooking-Duration:{cooking_duration}-mins</b></h3>
         <p><b>Instructions: {instructions}</b></p>
         <button type="button" className="disBtn" onClick={saveReceipes}>Save</button>
       </div>);
};