import {useEffect,useState} from 'react';
import axios from 'axios';
import { Receipe } from '../components/receipeDisplay';
import "./Home.css" ;
import {useCookies } from 'react-cookie';
export const Home = () =>
{
   const[receipes,setReceipes] = useState([]);
   const[cookie,setCookies] = useCookies(["access_token"]);
   const[search,setSearch] = useState("empty");


   const searchReceipes = async () =>
   {
      try{
          const response = await axios.get(`https://receipe-api.debeshp.com/receipe/${search}`, {headers :{authorization : cookie.access_token}});
          console.log(response.data);
          setReceipes(response.data);
      }
      catch(err)
      {
         console.log("Bad Request...!!!")
      }
   }

   useEffect( () => {
    
    searchReceipes();
   } ,[]);

   return (<div className='display'>
    <h1> Welcome To The Home Page</h1>

    <div class="searchContainer">
     <div class="receipeSearch">
      <input type="text" placeholder="Search here..." onChange={ (event) => setSearch(event.target.value) } />
      <button type="button" onClick={searchReceipes}>Enter</button>
     </div>
    </div>

    {receipes?.length > 0 ? (
        <div className="container">
          {receipes.map((receipe) => (
            <Receipe receipe={receipe} />
          ))}
        </div>
      ) : (
        <div className="empty">
          <h1>No Receipes found</h1>
        </div>
      )}
   </div>) ;
};