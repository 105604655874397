import './Login.css';
import { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom'
export const Login = () =>
{
   const[username,setUserName] = useState("");
   const[password,setPassword] = useState("");
   const[_,setCookies] = useCookies(["access_token"]);
   const navigate = useNavigate();

   const login = async () =>
   {
      try{

         const response = await axios.post( "https://receipe-api.debeshp.com/users/login", {username,password} );
         const token = response.data.token;
         setCookies("access_token",token);
         window.localStorage.setItem("user_id",response.data.id);
         navigate("/home");

      }
      catch(err)
      {
         alert("Invalid attempt!!!!");
      }
   };


   return (<div className='login'>
   <div className='logWrapper'>
   <form>
   <h1>Login</h1>
   <label htmlFor="username">Username:</label>
   <input type="text" id="username" onChange={ (event) => setUserName(event.target.value)  } /><br />
   <label htmlFor="password">Password:</label>
   <input type="password" id="password" onChange={ (event) => setPassword(event.target.value)  } /><br />
   <button type='button' onClick={login} className='logBtn'>Login</button>
   </form>
   </div>
  </div>) ;
};