import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Navbar } from './components/navbar';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { CreateRecipe } from './pages/CreateRecipe';
import { Home } from './pages/Home';
import { SavedRecipe } from './pages/SavedRecipe';


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/create' element={<CreateRecipe />} />
          <Route path='/home' element={<Home />} />
          <Route path='/saved' element={<SavedRecipe />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
