import {Link,useNavigate} from 'react-router-dom'
import "./navbar.css" ;
import { useCookies } from 'react-cookie';
export const Navbar = () =>
{

    const[cookies,setCookies] = useCookies(["access_token"])
    const navigate = useNavigate();
    const logout = () =>
    {
       setCookies("access_token","");
       window.localStorage.removeItem("user_id");
       navigate("/");
    }

    return (<div className='navbar'>
    <div className='links'>
        {
            cookies.access_token ? (<> <Link to="/home">Home</Link>
            <Link to="/create">Create-Recipe</Link>
            <Link to="/saved">Saved-Recipe</Link>
            <button type='button' onClick={logout} className='btn'>Logout</button> </>) : ( <> <Link to="/">Login</Link>
            <Link to="/register">Register</Link> </>)
        }
   
   
    </div>
    </div>);
} 